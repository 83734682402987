import React, { useState } from "react";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { logout } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

const TopNavbar = ({ showNavBar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((state: any) => state.auth.user);
  const [open, setOpen] = useState(false);

  const handleSettings = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="py-7">
      <div className="flex flex-row items-center justify-end">
        {showNavBar ? (
          <>
            <SettingsOutlinedIcon
              className="mr-5 text-primary cursor-pointer"
              onClick={() => handleSettings()}
            />
            <img src={user?.picture} alt="" className="w-8 mr-5 rounded-full" />
            <p className="text-primary font-normal font-body">{user?.name}</p>{" "}
          </>
        ) : (
          <div className="h-8" />
        )}
      </div>
      {open && (
        <div
          className="flex justify-end cursor-pointer absolute right-[5.75rem] top-[60px]"
          onClick={() => handleLogout()}
        >
          <p className="py-1 px-4 border border-primary rounded-lg mr-16 text-primary font-normal font-body">
            Logout
          </p>
        </div>
      )}
    </div>
  );
};

export default TopNavbar;
