
const dev = {
  apiGateway: {
    frontendURL: process.env.REACT_APP_FRONTEND_URL,
    tenantURL: process.env.REACT_APP_TENANT_URL,
    authURL: process.env.REACT_APP_AUTH_URL,
    ingredientURL: process.env.REACT_APP_INGREDIENT_URL,
    recipeURL: process.env.REACT_APP_RECIPE_URL
  },
};


const config = dev;

export default config;

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const checkRolePermission = (checkPermission: any, group: any, isCurrentUser: any, currentUser: any) => {
  let hasPermission = false;
  if (checkPermission?.includes("Organization recipes")) {
    hasPermission = true;
  } else if (checkPermission?.includes("Groups recipes")) {
    hasPermission = group.some((obj1: any) => {
      return currentUser.groups.find((obj2: any) => {
        return obj1.name === obj2.name;
      });
    });
  } else if (
    checkPermission?.includes("Users own recipes") &&
    isCurrentUser
  ) {
    hasPermission = true;
  }
  return hasPermission;
}