import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import recipeService from "./recipeService";
// import { toast } from "react-toastify";

const initialState = {
  recipe: {
    name: "",
    description: "",
    groups: [],
    ingredientId: [],
    order: [],
    solutions: [],
    manual: null,
    week: null,
    hour: null,
    schedule_type: null,
    permissions_enabled: false,
    start_date: null,
    active: null,
    permissionsReq: null,
    rt_tool: null,
    rt_tool_mapped: null
  },
  selectedRecipe: {},
  recipeList: [],
  filteredRecipes: [],
  recipeFilters: {
    sortBy: "",
    status: [],
    date: [],
    userList: [],
    groupList: []
  },
};

export const listRecipes = createAsyncThunk("recipe/list", async (tenant_id: any, thunkAPI) => {
  try {
    return await recipeService.listRecipes(tenant_id);
  } catch (error: any) {
    const message: any =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return message;
  }
});

export const getRecipe = createAsyncThunk(
  "recipe/get",
  async (id: any, thunkAPI) => {
    try {
      return await recipeService.getRecipe(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return {message: message, status: 400};
    }
  }
);

export const getSelectedRecipe = createAsyncThunk(
  "recipe/getSelectedRecipe",
  async (id: any, thunkAPI) => {
    try {
      return await recipeService.getStatus(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const getStatus = createAsyncThunk(
  "recipe/getStatus",
  async (id: any, thunkAPI) => {
    try {
      return await recipeService.getStatus(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const copyRecipe = createAsyncThunk(
  "recipe/copy",
  async ({ id, payload }: any, thunkAPI) => {
    try {
      return await recipeService.copyRecipe(id, payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const CreateScheduledRecipe = createAsyncThunk(
  "recipe/CreateScheduledRecipe",
  async (payload: any, thunkAPI) => {
    try {
      return await recipeService.CreateScheduledRecipe(payload);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const CreateManualRecipe = createAsyncThunk(
  "recipe/CreateManualRecipe",
  async ({filesToUpload, finalRecipe}: any, thunkAPI) => {
    try {
      return await recipeService.CreateManualRecipe(filesToUpload, finalRecipe);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);


export const editScheduledRecipe = createAsyncThunk(
  "recipe/editScheduledRecipe",
  async ({ finalRecipe, recipeId }: any, thunkAPI) => {
    try {
      return await recipeService.editScheduledRecipe(finalRecipe, recipeId);
    } catch (error: any) {      
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const editManualRecipe = createAsyncThunk(
  "recipe/editManualRecipe",
  async ({ filesToUpload, finalRecipe, recipeId }: any, thunkAPI) => {
    try {
      return await recipeService.editManualRecipe(filesToUpload, finalRecipe, recipeId);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const deleteRecipe = createAsyncThunk(
  "recipe/delete",
  async (id: any, thunkAPI) => {
    try {
      return await recipeService.deleteRecipe(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const setRecipeDetail = createAsyncThunk(
  "recipe/setRecipeDetail",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const setAllRecipeDetails = createAsyncThunk(
  "recipe/setAllRecipeDetails",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const clearRecipeState = createAsyncThunk(
  "recipe/clearRecipeState",
  async (thunkAPI) => {
    try {
      return;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const filteredRecipeState = createAsyncThunk(
  "recipe/filteredRecipeState",
  async (payload: any, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const viewIngredientFields = createAsyncThunk(
  "recipe/viewIngredientFields",
  async ({ selectedIngredientsIDs, selectedIngredientType }: any, thunkAPI) => {
    try {
      return await recipeService.viewIngredientFields(
        selectedIngredientsIDs,
        selectedIngredientType
      );
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return message;
    }
  }
);

export const checkPermission = createAsyncThunk(
  "recipe/checkPermission",
  async (id: any, thunkAPI) => {
    try {
      return await recipeService.checkPermission(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const runRecipe = createAsyncThunk(
  "recipe/runRecipe",
  async (id: any, thunkAPI) => {
    try {
      return await recipeService.runRecipe(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const pauseRecipe = createAsyncThunk(
  "recipe/pauseRecipe",
  async (id: any, thunkAPI) => {
    try {
      return await recipeService.pauseRecipe(id);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const fetchFiles = createAsyncThunk(
  "recipe/fetchFiles",
  async ({filedName, recipeId}: any, thunkAPI) => {
    try {
      return await recipeService.fetchFiles(filedName, recipeId);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const deleteFiles = createAsyncThunk(
  "recipe/deleteFiles",
  async (filePath: any, thunkAPI) => {
    try {
      return await recipeService.deleteFiles(filePath);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const downloadFiles = createAsyncThunk(
  "recipe/downloadFiles",
  async (mediaLink: any, thunkAPI) => {
    try {
      return await recipeService.downloadFiles(mediaLink);
    } catch (error: any) {
      const message: any =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const recipeSlice = createSlice({
  name: "recipe",
  initialState,
  reducers: {
    reset: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(listRecipes.fulfilled, (state, action) => {
        state.recipeList = action.payload;
      })
      .addCase(getRecipe.fulfilled, (state, action) => {
        state.recipe = action.payload;
      })
      .addCase(getRecipe.rejected, (state, action) => {
        state.recipe = null;
      })
      .addCase(getSelectedRecipe.fulfilled, (state, action) => {
        state.selectedRecipe = null;
      })
      .addCase(setRecipeDetail.fulfilled, (state, action) => {
        state.recipe.name = action.payload.name;
        state.recipe.description = action.payload.description;
        state.recipe.groups = action.payload.groups;
      })
      .addCase(setAllRecipeDetails.fulfilled, (state, action) => {
        state.recipe = action.payload;
      })
      .addCase(viewIngredientFields.fulfilled, (state, action) => {
        state.recipe.ingredientId = action.payload.ingredientId;
        state.recipe.order = action.payload.order;
        state.recipe.solutions = action.payload.solutions;
        state.recipe.manual = action.payload.type;
      })
      .addCase(CreateScheduledRecipe.fulfilled, (state, action) => {
        state.recipe = initialState.recipe;
      })
      .addCase(CreateManualRecipe.fulfilled, (state, action) => {
        state.recipe = initialState.recipe;
      })
      .addCase(clearRecipeState.fulfilled, (state, action) => {
        state.recipe = initialState.recipe;
      })
      .addCase(checkPermission.fulfilled, (state, action) => {
        state.recipe.permissionsReq = action.payload;
      })
      .addCase(filteredRecipeState.fulfilled, (state, action) => {
        state.recipeFilters = action.payload;
      });
  },
});

export const { reset } = recipeSlice.actions;
export default recipeSlice.reducer;
