import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import LoginView from "./pages/LoginView";
import AuthView from "./pages/AuthView";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import LeftNavBar from "./components/LeftNavigation/LeftNavBar";
import PrivateRoute from "./components/Common/PrivateRoute";
import React, { useState } from "react";
import Dashboard from "pages/Dashboard";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { logout, setUser } from "features/auth/authSlice";
import { ToastContainer } from "react-toastify";
import Ingredients from "pages/Ingredients";
import RecipeIngredients from "pages/Recipes/RecipeIngredients";
import IngredientView from "pages/IngredientView";
import Recipes from "pages/Recipes/Recipes";
import CreateRecipe from "pages/Recipes/CreateRecipe";
import RecipeSummary from "pages/Recipes/RecipeSummary";
import Loader from "components/Common/Loader";
import RolesDashboard from "pages/Roles/RolesDashboard";
import RoleOptions from "pages/Roles/RoleOptions";
import RoleSummary from "pages/Roles/RoleSummary";
import RoleCreation from "pages/Roles/RoleCreation";
import NewRoleUserControl from "pages/Roles/NewRoleUserControl";
import NewRoleRecipeSetting from "pages/Roles/NewRoleRecipeSetting";
import GroupsDashboard from "pages/Groups/GroupsDashboard";
import GroupCreation from "pages/Groups/GroupCreation";
import GroupUserSelection from "pages/Groups/GroupUserSelection";
import GroupsView from "pages/Groups/GroupsView";
import UsersDashboard from "pages/Users/UserDashboard";
import UserCreation from "pages/Users/UserCreation";
import UserSummary from "pages/Users/UserSummary";
import EditRoleUserControl from "pages/Users/EditRoleUserControl";
import EditRoleRecipeSetting from "pages/Users/EditRoleRecipeSetting";
import { GoogleOAuthProvider } from "@react-oauth/google";
import config from "components/Common/config";
import axios from "axios";

function App() {
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    const refreshToken = async () => {
      if (localStorage?.Authorization) {
        if (localStorage?.Authorization !== "undefined") {
          const token = localStorage.Authorization;
          const tokenData: any = jwt_decode(token);
          const currentTime = Date.now() / 1000;
          if (tokenData.exp < currentTime) {
            // dispatch(logout());
            const refreshTokens = localStorage.getItem("RefreshToken");

            try {
              const result = await axios
                .post(`${config.apiGateway.tenantURL}/api/v1/users/auth/refresh`, {
                  refreshToken: refreshTokens,
                });

              const { accessToken, refreshToken } = result.data;

              localStorage.setItem("Authorization", accessToken);
              localStorage.setItem("RefreshToken", refreshToken);
            } catch (error) {
              console.error("Error refreshing token:", error);
            }
          } else {
            let userRole = tokenData.payload.roles[0];
            userRole.resources = JSON.parse(userRole.resources);
            let user: any = {
              name: tokenData?.payload?.name,
              userID: tokenData?.payload?.id,
              picture: tokenData?.payload?.imageUrl,
              domain: tokenData?.payload?.organisation?.domain,
              tenant: tokenData?.payload?.organisation?.id,
              role: userRole,
              groups: tokenData?.payload?.groups,
              tc_verified: tokenData?.payload?.tc_verified,
            };
            dispatch(setUser(user)).then(() => {
              navigate("/recipes");
            });
          }
        } else {
          dispatch(logout());
        }
      } else {
        dispatch(logout());
      }
      setLoading(true);
    };

    refreshToken();
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={8080}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Loader />
      {loading ? (
        <GoogleOAuthProvider clientId="811635144452-1fvlf24974n2oa0i73a3dbba5ln1uc6d.apps.googleusercontent.com">
          {isAuthenticated && window.location.href !== config.apiGateway.frontendURL && (
            <LeftNavBar />
          )}
          <Routes>
            <Route path="/" element={<LoginView />} />
            <Route path="/auth" element={<AuthView />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/ingredients" element={<Ingredients />} />
              <Route path="/recipes" element={<Recipes />} />
              <Route path="/recipe_details" element={<CreateRecipe />} />
              <Route path="/recipe_ingredients" element={<RecipeIngredients />} />
              <Route path="/ingredients/:id" element={<IngredientView />} />
              <Route path="/recipe_summary" element={<RecipeSummary />} />
              <Route path="/roles" element={<RolesDashboard />} />
              <Route path="/create_role_options" element={<RoleOptions />} />
              <Route path="/role_summary" element={<RoleSummary />} />
              <Route path="/role_create" element={<RoleCreation />} />
              <Route path="/user_control" element={<NewRoleUserControl />} />
              <Route path="/recipe_setting" element={<NewRoleRecipeSetting />} />
              <Route path="/groups" element={<GroupsDashboard />} />
              <Route path="/group_create" element={<GroupCreation />} />
              <Route path="/group_user_selection" element={<GroupUserSelection />} />
            </Route>
            <Route path="/group_view" element={<GroupsView />} />
            <Route path="/users" element={<UsersDashboard />} />
            <Route path="/user_create" element={<UserCreation />} />
            <Route path="/user_summary" element={<UserSummary />} />
            <Route path="/edit_user_control" element={<EditRoleUserControl />} />
            <Route path="/edit_recipe_setting" element={<EditRoleRecipeSetting />} />
          </Routes>
        </GoogleOAuthProvider>
      ) : null}
    </>
  );
}

export default App;
