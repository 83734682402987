import { HeaderButton } from "components/Common/Button";
import React from "react";
import DocumentTitle from "./DocumentTitle";
import TopNavbar from "./TopNavbar";

const PageHeader = ({
  title,
  pageTitle,
  item,
  button,
  onClick,
  disabled = false,
  extraClassName = "",
  children,
  showNavBar = true
}: any) => {
  return (
    <div className={"sticky top-0 bg-[#FAFCFF] z-10 pb-1 "+extraClassName}>
      <TopNavbar showNavBar={showNavBar} />
      <div className={"flex items-center justify-between"}>
        <DocumentTitle pageTitle={title ?? pageTitle} />
        <div className="text-2xl my-4 text-primary font-bold">
          {title}
        </div>
        {button && (
          <HeaderButton text={button} onClick={onClick} disabled={disabled} />
        )}
        {item}
      </div>
      <hr className="border-1 border-primary opacity-40" />
      {children && 
        <div className="flex justify-between item-start py-3">
          {children}
        </div>
      }
    </div>
  );
};

export default PageHeader;
